<template>
  <section class="work-order-management" @click.stop="pickerclick" style="height: 90vh;overflow-y: auto;">
    <div class="top-bar bg-white">
      <el-radio-group style="color: black;width: 100% !important; margin-bottom:10px" v-model="currentTab"
                      @change="handleTabChange">
        <el-radio-button :label="1">
          <el-badge :value="all">
            <div style="width: 100px;">
              合同备案
            </div>
          </el-badge>
        </el-radio-button>
        <el-radio-button :label="2">
          <el-badge :value="expire">
            <div style="width: 100px;">
              房源上架
            </div>
          </el-badge>
        </el-radio-button>
      </el-radio-group>
    </div>
    <!-- 合同备案 -->
    <div v-if="currentTab === 1">
      <div class="top-bar bg-white">
        <div style="min-width: 50px;margin-top: 5px">关键字</div>
        <el-input v-model="formSearch.keyword" placeholder="请输入房间核验码/合同编号"
                  style="margin: 0 7px; width: 200px"
                  size="small" clearable/>
        <div style="min-width: 70px;margin-top: 5px">房屋坐落</div>
        <el-input v-model="formSearch.apartmentName" placeholder="请输入房屋座落" style="margin: 0 7px; width: 200px"
                  size="small"
                  clearable/>
        <div style="min-width: 90px;margin-top: 5px">经纪人姓名</div>
        <el-input v-model="formSearch.publisher" placeholder="请输入经纪人姓名" style="margin: 0 7px; width: 200px"
                  size="small"
                  clearable/>
        <div style="min-width: 70px;margin-top: 5px">出租方式</div>
        <el-select ref="districtRef" v-model="formSearch.rentType" clearable filterable allow-create
                   default-first-option size="small"
                   placeholder="请选择出租方式">
          <el-option label="全部" value=""/>
          <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item"/>
        </el-select>
        <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>
        <el-button type="primary" size="small" @click="close()">批量关闭</el-button>
      </div>
      <r-e-table class="bg-white" @selection-change="myhandleSelectionChange" :key="currentTab" ref="orderTableRef"
                 :dataRequest="getWordListTableData" :columns="tableColumn"
                 :query="formSearch" tooltip-effect="light" :height="790" :row-style="rowStyle"
                 :cell-style="cellStyle">
        <template slot="empty">
          <el-empty/>
        </template>
        <el-table-column slot="toolbar" label="操作" width="250">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="AvailabilityVerification(row)">房源核验</span>
              <span class="table-btn" @click="contractFiling(row)">重新审批</span>
                            <span class="table-btn" @click="queryContractAuditStatus(row)">审批状态</span>
              <span class="table-btn" @click="close(row)">关闭</span>
            </div>

          </template>
        </el-table-column>
      </r-e-table>
    </div>
    <!-- 房源上架 -->
    <div v-else-if="currentTab === 2">
      <div class="top-bar bg-white">
        <div style="min-width: 80px;margin-top: 5px">房间核验码</div>
        <el-input v-model="TransferFormSearch.keyword" placeholder="请输入房间核验码"
                  style="margin: 0 7px; width: 200px"
                  size="small" clearable/>
        <div style="min-width: 70px;margin-top: 5px">房屋坐落</div>
        <el-input v-model="TransferFormSearch.apartmentName" placeholder="请输入房屋座落"
                  style="margin: 0 7px; width: 200px" size="small"
                  clearable/>
        <div style="min-width: 70px;margin-top: 5px">经纪人姓名</div>
        <el-input v-model="TransferFormSearch.publisher" placeholder="请输入经纪人姓名"
                  style="margin: 0 7px; width: 200px" size="small"
                  clearable/>
        <div style="min-width: 70px;margin-top: 5px">出租方式</div>
        <el-select ref="stateRef" v-model="TransferFormSearch.rentType	" size="small" placeholder="请选择出租方式"
                   clearable
                   style="margin-right: 10px; width: 200px">

          <el-option label="全部" value=""/>
          <el-option label="整租" value="整租"/>
          <el-option label="合租" value="合租"/>
        </el-select>
        <el-button type="primary" size="small" style="margin: 0 7px;" @click="transferHandleSearch">查询</el-button>
        <el-button @click="transferHandleReset" size="small" style="margin: 0 7px;">重置</el-button>
        <el-button type="primary" size="small" @click="closehome()">批量关闭</el-button>
      </div>
      <r-e-table class="bg-white" @selection-change="myhandleSelectionChange"  :key="currentTab" ref="orderTransferTableRef"
                 :dataRequest="getTransferWordListTableData" :columns="tableColumns"
                 :query="TransferFormSearch" tooltip-effect="light" :height="790" :row-style="rowStyles"
                 :cell-style="cellStyles">
        <template slot="empty">
          <el-empty/>
        </template>
        <el-table-column slot="toolbar" label="操作" width="200">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="AvailabilityVerification(row)">房源核验</span>
              <span class="table-btn" @click="handlePublish(row)">重新推送</span>
              <!--              <span class="table-btn">审批状态</span>-->
              <span class="table-btn" @click="closehome(row)">关闭</span>
            </div>
          </template>
        </el-table-column>
      </r-e-table>
    </div>

    <dialogVerification ref="dialogVerification" :currentHouse="currentHouse"></dialogVerification>
    <dialog-approval ref="dialogApproval"/>
    <layer-bzf-publish ref="layerBzfPublish" @handleSearch="transferHandleSearch"/>
    <!--   发布弹框       -->
    <r-e-dialog title="发布" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel">
      <el-form title="" ref="formPublish" label-width="130px" size="small" :model="formPublish" :rules="rules">
        <el-form-item label="地址" prop="name">
          <el-input disabled v-model="formPublish.name"/>
        </el-form-item>
        <el-form-item label="月租金(元/月)" prop="monthAmount">
          <el-input type="number" v-model="formPublish.monthAmount"/>
        </el-form-item>
        <el-form-item label="季租金(元/月)" prop="seasonAmount">
          <el-input type="number" v-model="formPublish.seasonAmount"/>
        </el-form-item>
        <el-form-item label="半年租金(元/月)" prop="halfAmount">
          <el-input type="number" v-model="formPublish.halfAmount"/>
        </el-form-item>
        <el-form-item label="年租金(元/月)" prop="yearAmount">
          <el-input type="number" v-model="formPublish.yearAmount"/>
        </el-form-item>
        <el-form-item label="押金(元/月)" prop="bone">
          <el-input type="number" v-model="formPublish.bone"/>
        </el-form-item>
        <!--        <el-form-item label="定金(元/月)" prop="deposit">-->
        <!--          <el-input type="number" v-model="formPublish.deposit"/>-->
        <!--        </el-form-item>-->
        <el-form-item label="服务费(元/月)" prop="tipAmount">
          <el-input type="number" v-model="formPublish.tipAmount"/>
        </el-form-item>
        <el-form-item label="保租房项目" style="width: 100%;">
          <el-select v-model="projectId" placeholder="请选择" filterable style="width: 100%;">
            <el-option v-for="item in projectList" :key="item.id" :label="item.project_name" :value="item.id"/>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="住建平台" style="width: 100%;" prop="verificationCode">-->
<!--          <el-radio v-model="formPublish.verificationCode" :label="true">推送</el-radio>-->
<!--          <el-radio v-model="formPublish.verificationCode" :label="false">不推送</el-radio>-->
<!--          <div style="color: red">当选择推送至住建平台，仅具备有住建房源核验码才可推送</div>-->
<!--        </el-form-item>-->
      </el-form>
    </r-e-dialog>
  </section>
</template>

<script>
import {getAddressList} from "@/api/community-management";
import {tableWorkOrderColumn, tableTransferWorkOrderColumn} from "./data";
import {getcontractBeiAnApi, wordExport, getTransferWordList, transferwordExport} from "@/api/work-order-management";
import {timeFormat} from "@custom/index";
import {downloadByData} from "@/utils/hooks/download";
import {buttonCookies} from "@storage/cookies";
import {getapartmentPublishApi, POSTapartmentPublishAPI, POSTreadAPI} from "../../../api/work-order-management";
import {MessageSuccess, MessageWarning} from "../../../utils/custom/message";
import {bzfProjectPut, getApartmentDetails, getBzfProject, isApartmentType} from "../../../api/house-resource";
import {ParseFloatFormat, ParseIntFormat} from "../../../utils/custom";
import {publishApartment} from "../../../api/publish";
import {queryContractAuditStatus} from "../../../api/contract";

export default {
  name: "work-order-management",
  data() {
    return {
      projectId: null,
      projectList: [],
      formPublish: {
        apartmentUuid: null,
        name: null,
        monthAmount: null,
        seasonAmount: null,
        halfAmount: null,
        yearAmount: null,
        bone: null,
        deposit: null,
        tipAmount: null,
        verificationCode:true,
      },
      rules: {
        bone: [{required: true, message: "请输入押金", trigger: "blur"},],
        deposit: [{required: true, message: "请输入定金", trigger: "blur"},],
        tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
        monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
        seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
        halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
        yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
        verificationCode: [
          { required: true, message: '请选择是否推送', trigger: 'change' }
        ],
      },
      dialogVisible: false,
      rowlist: [],
      // 审批
      itr: true,
      tableColumn: tableWorkOrderColumn(this),
      currentHouse: '',
      formSearch: {
        keyword: null,
        apartmentName: null,
        publisher: null,
        rentType: null,

      },
      startAndEndDate: [],
      stateList: [],
      tfStateList: [
        {code: -1, codeName: '全部',},
        {code: 0, codeName: '待发起',},
        {code: 1, codeName: '待片管员预审',},
        {code: 2, codeName: '待负责人审核',},
        {code: 3, codeName: '待财务审核',},
        {code: 4, codeName: '已完成',},
      ],
      hfStateList: [
        {code: -1, codeName: '全部',},
        {code: 2, codeName: '待确认',},
        {code: 3, codeName: '待处理',},
        {code: 6, codeName: '已完成',},
      ],
      options: ['整租', '分租'], //所属区域下拉数据存放
      currentTab: 1, // 默认展示房源退换工单
      // 押金转移工单
      tableColumns: tableTransferWorkOrderColumn(this),
      TransferFormSearch: {
        keyword: null, //工单编号
        apartmentName: null, //租户名 提交人名称
        publisher: null, //工单状态
        rentType: null, //工单状态
      },
      TransferStartAndEndDate: [],
    };
  },
  async mounted() {
    //选择区域接口请求
    const {msg} = await getBzfProject();
    const {result} =  JSON.parse(msg)
    // console.log(result);
    this.projectList = result;
  },
  components: {
    dialogVerification: () => import("./components/dialog-verification"),
    dialogApproval: () => import("./components/dialog-approval"),
    layerBzfPublish: () => import("./components/layer-bzf-publish"),
  },
  deactivated() {
    // alert('离开')
    // 2024-7-10-陈守亮处理切换页面关闭组件
    this.pickerclick()
  },
  methods: {
    async queryContractAuditStatus(data) {
      const that = this;
      const {loadingOptions} = that;
      let loading = that.$loading(loadingOptions);
      //调用接口查询审批状态
      const res = await queryContractAuditStatus({uuid: data.uuid}).finally(() => loading.close());
      if (res) {
        const {info} = res;
        //提示审批状态
        MessageSuccess(info.message);
      }
    },
    handleCancel(closeType = "cancel") {
      // 取消、关闭弹窗
      this.dialogVisible = false;
      // this.formPublish = {};
      this.$refs.formPublish.resetFields();
      // 新增、编辑成功，需要刷新数据
      // if (closeType === "update") this.$refs.apartmentTableRef.getTableData();
    },
    handleSubmitPublish() {
      console.log('8888')
      // alert()
      let that = this;
      // 表单提交
      this.$refs.formPublish.validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          const loadingOptions = this.loadingOptions;
          const loading = this.$loading({...loadingOptions});
          //that.formPublish.amount *= 100;
          let {bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount} = that.formPublish;
          bone = ParseIntFormat(bone);
          deposit = ParseIntFormat(deposit);
          tipAmount = ParseIntFormat(tipAmount);
          monthAmount = ParseIntFormat(monthAmount);
          seasonAmount = ParseIntFormat(seasonAmount);
          halfAmount = ParseIntFormat(halfAmount);
          yearAmount = ParseIntFormat(yearAmount);

          that.formPublish.monthAmount = monthAmount;
          that.formPublish.seasonAmount = seasonAmount;
          that.formPublish.halfAmount = halfAmount;
          that.formPublish.yearAmount = yearAmount;
          that.formPublish.bone = bone;
          that.formPublish.deposit = deposit;
          if (that.formPublish.propertyCode === 600221) that.formPublish.tipAmount = tipAmount;
          else that.formPublish.tipAmount = 0;
          console.log(that.formPublish)
          publishApartment(this.formPublish).then(res => {
            MessageSuccess(`${this.formPublish.name} 发布成功!`);
            // that.handleCancel("update");
          }).finally(() => loading.close());
        } else {
          MessageWarning("请填写必填值");
          return false;
        }
      });
    },
    // 重新发布
    async handlePublish(row) {
      let newrow = {}
      await getApartmentDetails(row.apartmentUuid).then(res => {
        console.log(res)
        newrow = res.info
      }).catch(err => {
        console.log(err)
      })
      let {
        propertyCode,
        apartmentName,
        amount,
        bone,
        deposit,
        tipAmount,
        apartmentUuid,
        monthAmount,
        seasonAmount,
        halfAmount,
        yearAmount,
        insideArea,
        outsideArea,
        monthPriceAmount,
        yearDiscount,
        sixMonthDiscount
      } = newrow;

      let uuid = row.apartmentUuid
      let name = row.apartmentName
      const ress = await isApartmentType(uuid);
      console.log('ress', ress);
      // alert(re.info)
      // 发布打开弹窗
      if (ress.info === 0 || ress.info === 2) {
        this.$refs["layerBzfPublish"].openDialog(row);
      } else {
        this.dialogVisible = true;
      }


      monthPriceAmount = ParseFloatFormat(monthPriceAmount);

      bone = ParseFloatFormat(bone);
      deposit = ParseFloatFormat(deposit);
      tipAmount = ParseFloatFormat(tipAmount);
      monthAmount = ParseFloatFormat(monthAmount);
      seasonAmount = ParseFloatFormat(seasonAmount);
      halfAmount = ParseFloatFormat(halfAmount);
      yearAmount = ParseFloatFormat(yearAmount);

      this.formPublish = {
        propertyCode, name, apartmentUuid: uuid, bone, deposit, tipAmount, monthAmount, seasonAmount,
        halfAmount, yearAmount
      };
      console.log(this.formPublish)
    },
    // 房间上架关闭
    closehome(row = null) {
      if(row === null){
        MessageWarning('请先选择数据');
        return
      }
      let data = {}
      this.$confirm('请确认所选房源问题是否已处理完成，关闭操作将使此信息清除?', '确认要关闭信息吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loadingOptions = this.loadingOptions;
        const loading = this.$loading({...loadingOptions});
        if (row === null) {
          data = {
            uuids: this.rowlist
          }
          console.log(this.rowlist)
        } else {
          data = {
            uuids: [row.apartmentUuid]
          }
          console.log(this.rowlist)
        }
        POSTapartmentPublishAPI(data).then(res => {
          console.log(res)
          MessageSuccess('关闭成功');
          this.handleSearch()

        }).catch(err => {
        }).finally(() => loading.close());
      }).catch(() => {

      });
    },
    close(row = null) {
      if(row === null){
        MessageWarning('请先选择数据');
        return
      }
      let data = null
      this.$confirm('请确认所选房源问题是否已处理完成，关闭操作将使此信息清除?', '确认要关闭信息吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loadingOptions = this.loadingOptions;
        const loading = this.$loading({...loadingOptions});
        if (row === null) {
          data = {
            uuids: this.rowlist
          }
          console.log(data,'批量')
          POSTreadAPI(data).then(res => {
            console.log(res)
            MessageSuccess('关闭成功');
            this.handleSearch()

          }).catch(err => {
          }).finally(() => loading.close());
        } else {
          data = {
            uuids: [row.uuid]
          }
          console.log(data,'单个')
          POSTreadAPI(data).then(res => {
            console.log(res)
            MessageSuccess('关闭成功');
            this.handleSearch()

          }).catch(err => {
          }).finally(() => loading.close());
          console.log(this.rowlist)
        }

      }).catch(() => {

      });

    },
    // 重新审批
    contractFiling(data) {
      //调用组件方法，打开新增合同备案弹框
      this.$refs["dialogApproval"].openDialog(data.uuid, 1);
    },
    // 房源核验事件
    AvailabilityVerification(row) {
      this.currentHouse = row
      this.$nextTick(() => {
        this.$refs["dialogVerification"].openDialog();
      })
    },
    // 批量审核事件
    myhandleSelectionChange(row) {
      console.log(row)
      if(this.currentTab===1){
        this.rowlist = row.map(item => {
          return item.uuid
        })
      }else if(this.currentTab===2){
        this.rowlist = row.map(item => {
          return item.apartmentUuid
        })
      }
    },
    getWordListTableData(params) {
      return getcontractBeiAnApi(params);
    },
    handleSearch() {
      this.$refs["orderTableRef"].getTableData(true);
    },
    handleReset() {
      this.startAndEndDate = [];
      this.formSearch = {wordNum: null, name: null, status: null, state: null};
      Promise.resolve(this.formSearch).then(() => this.handleSearch());
    },
    openDialog(openName = "", data, openDialog) {
      console.log(data)
      // alert('999')
      // alert(openName,'11')
      if (openName) this.$refs[openName].openDialog(data, openDialog);
    },


    rowStyle({row, rowIndex}) {
      let {state, wordType} = row;
      if (state === 2) return {backgroundColor: '#FFF5F5'};
      if (state === 6) return {backgroundColor: '#E5EFFF'};
    },
    // rowStyles({row, rowIndex}) {
    //     let {status, wordType} = row;
    //     if (status === 0) return {backgroundColor: '#FFF5F5'};
    //     if (status === 6) return {backgroundColor: '#E5EFFF'};
    // },

    cellStyle({row, column, rowIndex, columnIndex}) {
      let {state} = row;
      if (state === 2 && columnIndex === 1) return {color: '#FF3718'};
      if (state === 3 && columnIndex === 1) return {color: '#FF3718'};
      if (state === 6 && columnIndex === 1) return {color: '#1890FF'};
    },
    cellStyles({row, column, rowIndex, columnIndex}) {
      let {status} = row;
      if (status === 0 && columnIndex === 1) return {color: '#FF3718'};
      if (status === 1 && columnIndex === 1) return {color: '#FF3718'};
      if (status === 2 && columnIndex === 1) return {color: '#FF3718'};
      if (status === 3 && columnIndex === 1) return {color: '#FF3718'};
    },


    getButtonMenu(menu) {
      return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
    },

    // 2024-4-16-新增点击空白关闭组件
    pickerclick(e) {
      console.log(this.itr)
      this.itr = !this.itr;
      if (this.itr) {
        this.$refs['pickerRef'].hidePicker();
      }
      this.$refs['selectstatus'].blur();
      this.$refs['stateRef'].blur();
      this.$refs['districtRef'].blur();
      console.log(this.itr)
    },
    pickerclickitr() {
      this.itr = true;
      console.log(this.itr)
    },
    //2024/08/29 张晓瑜切换按钮更换工单列表
    handleTabChange(value) {
      this.currentTab = value;
      this.rowlist = []
      this.$nextTick(() => {
        if (value === 1) {
          this.getWordListTableData();
        } else if (value === 2) {
          this.getTransferWordListTableData();
        }
      });
    },
    //房源上架
    getTransferWordListTableData(params) {
      return getapartmentPublishApi(params);
    },
    // 房源上架搜索
    transferHandleSearch() {
      this.$refs["orderTransferTableRef"].getTableData(true);
    },
    // 押金转移工单重置
    transferHandleReset() {
      this.TransferStartAndEndDate = [];
      this.TransferFormSearch = {workOrderNum: null, leasorName: null, status: null,};
      Promise.resolve(this.TransferFormSearch).then(() => this.transferHandleSearch());
    },


  },
  watch: {
    startAndEndDate(value) {
      if (value) {
        let [startDate, endDate] = value;
        this.formSearch.startDate = timeFormat(startDate);
        this.formSearch.endDate = timeFormat(endDate);
      } else {
        this.formSearch.startDate = null;
        this.formSearch.endDate = null;
      }
    },
    TransferStartAndEndDate(value) {
      if (value) {
        let [startDate, endDate] = value;
        this.TransferFormSearch.startDate = timeFormat(startDate);
        this.TransferFormSearch.endDate = timeFormat(endDate);
      } else {
        this.TransferFormSearch.startDate = null;
        this.TransferFormSearch.endDate = null;
      }
    },
    'formSearch.status'(value) {
      if (value) {
        if (value === '退房') {
          this.stateList = this.tfStateList;
          this.formSearch.state = null;
        }
        if (value === '换房') {
          this.stateList = this.hfStateList;
          this.formSearch.state = null;
        }
      } else {
        this.stateList = [];
        this.formSearch.state = null;
      }
    },
    projectId(value){
      if(value){
        let {apartmentUuid: uuid} = this.formPublish;
        bzfProjectPut({uuid,projectId:value});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.work-order-management {
  padding: VH(25px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    justify-content: flex-start;
  }

  /deep/ .r-e-table {
    height: 93%;

    .el-table .cell {
      //white-space: nowrap;
    }
  }
}
</style>
