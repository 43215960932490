import {timeFormat, numberFormat} from "@custom/index";
import {conversionAmount} from "@/utils/hooks";

//2022/03/27 王江毅 工单管理 工单列表
export function tableWorkOrderColumn(that) {
    return [
        {
            type: 'selection',
            width: 70,
            selectable:(row) => {
                // console.log(row)
                return !row.tuiFang;
            },
        },
        {
            type: "index",
            label: "序号",
            width: 50
        },
        {
            prop: "name",
            label: "房屋坐落",
            width: 180,
            render: (h, {row}) => {
                const {name} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName:name}}});
                        }
                    }
                }, name);
            }
            // render: (h, {row}) => {
            //     const {apartmentName} = row;
            //     return h("el-link", {
            //         props: {type: "primary", underline: false},
            //         on: {
            //             click() {
            //                 that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
            //             }
            //         }
            //     }, apartmentName);
            // }
        },
        {
            label: "房间核验码",
            width: 150,
            prop: 'housingCode'
        },
        {
            label: "合同编号",
            width: 180,
            render: (h, {row}) => {
                const {contractNum} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    // on: {
                    //     click() {
                    //         that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                    //     }
                    // }
                }, contractNum);
            }
        },
        {
            label: "出租方式",
            prop: 'rentType'
        },
        {
            label: "经纪人姓名",
            width: 100,
            prop: 'brokerName'
        },
        {
            prop: "businessNo",
            label: "修改业务编号",
            width: 140,
        },
        {
            prop: "apiResponse",
            label: "备案返回结果",
            width: 300,
        },
        // {
        //     // prop: "smsContent",
        //     label: "备案返回结果",
        //     // showOverflowTooltip: true,
        //     render: (h, param) => {
        //         const {apiResponse} = param.row;
        //         // return smsContent
        //         //  let smsContent = '明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间' +
        //         //     '把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间,明月几时有？把酒问青天。不知天上宫阙，今夕是何年。我欲乘风归去，又恐琼楼玉宇，高处不胜寒。起舞弄清影，何似在人间'
        //         return h("el-link", {
        //             props: {type: "primary", underline: false},
        //             // on: {
        //             //     click() {
        //             //         let leasorName = '唐晓娴'
        //             //         _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
        //             //     }
        //             // }
        //         }, apiResponse);
        //         // let myoperateModuledom = <div style="height:58px;overflow-y: auto;width:400px;">{myoperateModule}</div>
        //         // return (myoperateModuledom)
        //         // return h("span", {
        //         //     style: {
        //         //
        //         //     }
        //         // }, smsContent);
        //     }
        // },
        {
            label: "备案时间",
            width: 160,
            render: (h, {row}) => {
                const {createdTime} = row;
                return createdTime ? timeFormat(new Date(row.createdTime),'yyyy-MM-dd HH:mm:ss') : "";
            }
        },
        {
            label: "审批状态",
            width: 140,
            render: (h, {row}) => {
                const {approvalStatus} = row;
                return approvalStatus
            }
        },
        {
            label: "状态更新时间",
            width: 140,
            render: (h, {row}) => {
                const {approvalTime} = row;
                return approvalTime ? timeFormat(new Date(approvalTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
    ];
}
export function tableTransferWorkOrderColumn(that) {
    return [
        {
            type: 'selection',
            width: 70,
            selectable:(row) => {
                // console.log(row)
                return !row.tuiFang;
            },
        },
        {
            type: "index",
            label: "序号",
            width: 50
        },
        {
            prop: "apartmentName",
            label: "房屋坐落",
            width: 180,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName:apartmentName}}});
                        }
                    }
                }, apartmentName);
            }
        },
        {
            label: "房屋状态",
            prop: "apartmentStatus",
            render: (h, {row}) => {
                const {apartmentStatus} = row;
                if(apartmentStatus === 600001) return '空置'
                if(apartmentStatus === 600002) return '预定'
                if(apartmentStatus === 600003) return '已租'
                if(apartmentStatus === 600004) return '欠租'
                if(apartmentStatus === 600005) return '到期'
                if(apartmentStatus === 600006) return '维修中'
                if(apartmentStatus === 600007) return '待保洁'

            }
        },
        {
            label: "房间核验玛",
            prop: "apartmentCode",
        },
        {
            label: "租赁方式",
            prop: 'rentType'
        },
        {
            prop: "publisher",
            label: "经纪人",
        },
        {
            label: "推送返回结果",
            render: (h, {row}) => {
                const {zhuJianPublishAPIResponse} = row;
                return zhuJianPublishAPIResponse
            }
        },
        {
            label: "推送时间",
            render: (h, {row}) => {
                const {zhuJianPublishTime} = row;
                return zhuJianPublishTime ? timeFormat(new Date(zhuJianPublishTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
    ];
}
